// src/components/Recommendations.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Recommendations({ items }) {
  const [recommendations, setRecommendations] = useState([]);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const response = await axios.post('http://localhost:5000/api/get-recommendations', { items });
        setRecommendations(response.data);
      } catch (error) {
        console.error('Error fetching recommendations:', error);
      }
    };

    fetchRecommendations();
  }, [items]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-4">
      {recommendations.map((rec) => (
        <div key={rec.id} className="bg-white shadow rounded-lg overflow-hidden">
          <img src={rec.imageUrl} alt={rec.description} className="w-full h-56 object-cover" />
          <div className="p-4">
            <p className="text-gray-800">{rec.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Recommendations;
