// src/pages/Home.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Camera } from 'lucide-react';

function Home() {
  return (
    <div className="p-8 text-center">
      <h1 className="text-5xl font-bold text-gray-800 mb-4">
        Clueless Closet
      </h1>
      <p className="text-gray-600 mb-8">
        Manage and style your wardrobe effortlessly with AI-powered
        recommendations.
      </p>
      <Link
        to="/add-item"
        className="inline-flex items-center px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600"
      >
        <Camera className="w-5 h-5 mr-2" />
        Add a New Item
      </Link>
    </div>
  );
}

export default Home;
