// src/components/Camera.js

import React, { useState } from 'react';
import axios from 'axios';
import { Loader } from 'lucide-react';

function Camera({ onAddItem, onPersonDetected }) {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCapture = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      processImage(file);
    }
  };

  const processImage = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file); // 'file' matches the FastAPI endpoint parameter

    try {
      const response = await axios.post(
        'https://sealed-tier-potter-brain.trycloudflare.com/api/process-image',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      const item = response.data;
      onAddItem(item); // Add the item to the wardrobe

      // Trigger the popup if a person is detected
      if (item.person) {
        onPersonDetected(); // Call the popup function
      }

      setLoading(false);
      setError(null);
    } catch (error) {
      console.error('Error processing image:', error);
      setError('Failed to process image.');
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      <input
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleCapture}
        className="mb-4"
      />
      {image && (
        <img
          src={image}
          alt="Captured"
          className="mt-4 w-full h-64 object-contain rounded-lg"
        />
      )}
      {loading && (
        <div className="flex items-center mt-4">
          <Loader className="w-5 h-5 mr-2 animate-spin" />
          <span>Processing image...</span>
        </div>
      )}
      {error && <p className="text-red-500 mt-4">{error}</p>}
    </div>
  );
}

export default Camera;
