// src/pages/RecommendationsPage.js

import React from 'react';
import Recommendations from '../components/Recommendations';

function RecommendationsPage({ items }) {
  return (
    <div>
      <h2 className="text-3xl font-bold p-4">Style Recommendations</h2>
      {items.length > 0 ? (
        <Recommendations items={items} />
      ) : (
        <p className="p-4 text-gray-600">Add items to your wardrobe to get recommendations.</p>
      )}
    </div>
  );
}

export default RecommendationsPage;
