// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Wardrobe from './pages/Wardrobe';
import AddItem from './pages/AddItem';
import RecommendationsPage from './pages/RecommendationsPage';
import EthicalPopup from './components/EthicalPopup';

function App() {
  const [items, setItems] = useState(() => {
    try {
      const storedItems = localStorage.getItem('wardrobeItems');
      return storedItems ? JSON.parse(storedItems) : [];
    } catch (error) {
      console.error('Failed to load wardrobe items from localStorage:', error);
      return [];
    }
  });

  const [showEthicalPopup, setShowEthicalPopup] = useState(false);
  const [doNotRemind, setDoNotRemind] = useState(() => {
    try {
      const storedPreference = localStorage.getItem('doNotRemindEthicalPopup');
      return storedPreference === 'true';
    } catch (error) {
      console.error('Failed to load popup preference from localStorage:', error);
      return false;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem('wardrobeItems', JSON.stringify(items));
    } catch (error) {
      console.error('Failed to save wardrobe items to localStorage:', error);
    }
  }, [items]);

  useEffect(() => {
    try {
      localStorage.setItem('doNotRemindEthicalPopup', doNotRemind);
    } catch (error) {
      console.error('Failed to save popup preference to localStorage:', error);
    }
  }, [doNotRemind]);

  const handleAddItem = (item) => {
    setItems((prevItems) => [...prevItems, item]);

    if (item.personDetected) {
      handlePersonDetected();
    }
  };

  const handleDeleteItem = (id) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
  };

  const handleEditItem = (id) => {
    const newTitle = prompt('Enter a new title for this dress:');
    if (newTitle) {
      const updatedItems = items.map((item) =>
        item.id === id ? { ...item, title: newTitle } : item
      );
      setItems(updatedItems);
    }
  };

  const handlePersonDetected = () => {
    if (!doNotRemind) {
      setShowEthicalPopup(true);
    }
  };

  const handleClosePopup = () => setShowEthicalPopup(false);

  const handleDoNotRemind = () => {
    setDoNotRemind(true);
    setShowEthicalPopup(false);
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/wardrobe"
              element={
                <Wardrobe
                  items={items}
                  onDelete={handleDeleteItem}
                  onEdit={handleEditItem}
                />
              }
            />
            <Route
              path="/add-item"
              element={
                <AddItem
                  onAddItem={handleAddItem}
                  onPersonDetected={handlePersonDetected}
                />
              }
            />
            <Route
              path="/recommendations"
              element={<RecommendationsPage items={items} />}
            />
          </Routes>
        </main>
        <Footer />
        {showEthicalPopup && (
          <EthicalPopup
            onClose={handleClosePopup}
            onDoNotRemind={handleDoNotRemind}
          />
        )}
      </div>
    </Router>
  );
}

export default App;
