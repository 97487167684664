// src/pages/Wardrobe.js

import React from 'react';
import Closet from '../components/Closet';

function Wardrobe({ items, onDelete, onEdit }) {
  return (
    <div>
      <h2 className="text-3xl font-bold p-4">Your Wardrobe</h2>
      {items.length > 0 ? (
        <Closet items={items} onDelete={onDelete} onEdit={onEdit} />
      ) : (
        <p className="p-4 text-gray-600">Your wardrobe is empty. Add some items!</p>
      )}
    </div>
  );
}

export default Wardrobe;
