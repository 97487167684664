// src/components/Navbar.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Shirt, PlusCircle, Gift } from 'lucide-react';

function Navbar() {
  return (
    <nav className="bg-white shadow p-4 flex items-center justify-between">
      <Link to="/" className="text-xl font-bold text-gray-800">
        Clueless Closet
      </Link>
      <div className="flex space-x-4">
        <Link
          to="/wardrobe"
          className="text-gray-600 hover:text-gray-800 flex items-center"
        >
          <Shirt className="w-5 h-5 mr-1" />
          Wardrobe
        </Link>
        <Link
          to="/add-item"
          className="text-gray-600 hover:text-gray-800 flex items-center"
        >
          <PlusCircle className="w-5 h-5 mr-1" />
          Add Item
        </Link>
        <Link
          to="/recommendations"
          className="text-gray-600 hover:text-gray-800 flex items-center"
        >
          <Gift className="w-5 h-5 mr-1" />
          Recommendations
        </Link>
      </div>
    </nav>
  );
}

export default Navbar;
