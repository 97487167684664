// src/components/ItemCard.js

import React, { useState } from 'react';
import { Tag, Trash2, Edit3, ChevronDown, ChevronUp } from 'lucide-react';

function ItemCard({ item, onDelete, onEdit }) {
  const [showMore, setShowMore] = useState(false);
  const MAX_LENGTH = 100; // Max characters before truncating

  const handleShowMore = () => setShowMore(!showMore);

  const renderDescription = () => {
    if (item.description.length <= MAX_LENGTH) {
      return item.description;
    }
    return showMore
      ? item.description
      : `${item.description.substring(0, MAX_LENGTH)}...`;
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden relative">
      <img
        src={item.imageUrl}
        alt={item.description}
        className="w-full h-56 object-contain"
      />
      <div className="p-4">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-lg font-bold">{item.title || `Dress #${item.id}`}</h3>
          <div className="flex space-x-2">
            <button
              onClick={() => onEdit(item.id)}
              className="text-gray-600 hover:text-gray-800"
            >
              <Edit3 className="w-5 h-5" />
            </button>
            <button
              onClick={() => onDelete(item.id)}
              className="text-red-500 hover:text-red-700"
            >
              <Trash2 className="w-5 h-5" />
            </button>
          </div>
        </div>
        <p className="text-gray-700 mb-2">{renderDescription()}</p>
        {item.description.length > MAX_LENGTH && (
          <button
            onClick={handleShowMore}
            className="text-blue-600 hover:underline"
          >
            {showMore ? 'Show Less' : 'Show More'}{' '}
            {showMore ? <ChevronUp className="w-4 h-4 inline-block" /> : <ChevronDown className="w-4 h-4 inline-block" />}
          </button>
        )}
        {item.keywords && item.keywords.length > 0 && (
          <div className="flex flex-wrap mt-2">
            {item.keywords.map((keyword, index) => (
              <span
                key={index}
                className="bg-blue-100 text-blue-800 text-sm font-semibold mr-2 mb-2 px-2.5 py-0.5 rounded"
              >
                <Tag className="inline-block w-4 h-4 mr-1" />
                {keyword}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ItemCard;
