// src/pages/AddItem.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Camera from '../components/Camera';

function AddItem({ onAddItem, onPersonDetected }) {
  const navigate = useNavigate();

  const handleAddItem = (item) => {
    onAddItem(item);
    // Redirect to the wardrobe after adding the item
    navigate('/wardrobe');
  };

  return (
    <div>
      <h2 className="text-3xl font-bold p-4">Add a New Item</h2>
      <Camera onAddItem={handleAddItem} onPersonDetected={onPersonDetected} />
    </div>
  );
}

export default AddItem;
