// src/components/Closet.js

import React from 'react';
import ItemCard from './ItemCard';

function Closet({ items, onDelete, onEdit }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-4">
      {items.map((item) => (
        <ItemCard
          key={item.id}
          item={item}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ))}
    </div>
  );
}

export default Closet;
