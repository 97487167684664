
// src/components/Footer.js

import React from 'react';

function Footer() {
  return (
    <footer className="bg-white shadow p-4 text-center text-gray-600">
      &copy; {new Date().getFullYear()} Clueless Closet
    </footer>
  );
}

export default Footer;
