// src/components/EthicalPopup.js

import React from 'react';
import { X, AlertTriangle } from 'lucide-react';

function EthicalPopup({ onClose, onDoNotRemind }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-1/3 relative">
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <X className="w-5 h-5" />
        </button>
        <div className="flex items-center mb-4">
          <AlertTriangle className="w-6 h-6 text-yellow-500 mr-2" />
          <h2 className="text-xl font-semibold">Privacy Notice</h2>
        </div>
        <p className="text-gray-700 mb-6">
          We noticed that the image you uploaded contains a person. To protect your privacy, you don't need to take photos of yourself or clothes on people. Our app works perfectly without including people in the frame.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
            onClick={onClose}
          >
            Close
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={onDoNotRemind}
          >
            Do Not Remind Me Again
          </button>
        </div>
      </div>
    </div>
  );
}

export default EthicalPopup;
